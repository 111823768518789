import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app'
import 'firebase/auth'
import { Link } from 'react-router-dom';
import logo from '../images/Subaru_logo.svg';
import '../styles/QuizHeader.css';

class QuizHeader extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { loggedIn } = this.props;
    return (
      <div className="QuizHeader h3 w-100 flex items-center bb b--light-gray bg-white">
        <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
          <div className="mw5">
            <Link to="/quiz/">
              <img alt="Subaru" src={logo} className="pl3" />
            </Link>
          </div>
          <div className="login pr3 flex items-center">
            {loggedIn && (
              <div className="flex flex-column">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    firebase.auth().signOut();
                    document.location.href = "/";
                  }}
                  className="f5 b"
                >Sign Out</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default inject((stores) => ({
  loggedIn: stores.app.loggedIn,
  isAdmin: stores.app.isAdmin
}))(observer(QuizHeader));
