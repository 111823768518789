import axios from 'axios'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { decorate, observable, action, computed, runInAction } from "mobx";
import set from 'lodash/set';

import UserSchema from '../schema/User';
import QuizSchema from '../schema/Quiz';
import { DATE_VALUES } from '../data'

const API_URL_BASE = process.env.REACT_APP_API_URL_BASE
const ADMINS_COLLECTION = 'admins';
const USERS_COLLECTION = 'users';

class AppStore {
  constructor() {
    // Auth
    this.checkedAuth = false;
    this.fetchedUser = false;
    this.userUID = '';
    this.userEmail = '';
    this.loggedIn = false;
    this.isAdmin = false;
    this.userDoc = {
      answers: {}
    };

    // Quiz
    this.fetchingQuiz = false;
    this.quiz = { sections: [] };
    this.fetechedQuiz = false;

    // Users
    this.users = {}
    this.fetchingUsers = false;
    this.fetchedUsers = false;

    // Search Filters
    this.search = '';
    this.city = '';
    this.group = '';
    this.eventDay = '';

    // Online/Offline
    this.onlineTime = 0;
    this.offlineTime = 0;
  }

  async signUp(values) {
    const {
      firstName,
      lastName,
      email,
      password,
      city,
      eventDay,
      group,
    } = values || {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      city: '',
      eventDay: '',
      group: '',
    };

    const userResult = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await firebase.firestore().collection(USERS_COLLECTION).doc(userResult.user.uid).set({
      firstName,
      lastName,
      city,
      eventDay,
      group,
      email,
    })
  }

  async signOut() {
    await firebase.auth().signOut()
  }

  monitorAuthState() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        runInAction(() => {
          this.checkedAuth = true;
          this.fetchedUser = false;
          this.userUID = user.uid;
          this.userEmail = user.email;
          this.loggedIn = true;
        });

        this.fetchCurrentUser();
      } else {
        runInAction(() => {
          this.checkedAuth = true;
          this.fetchedUser = false;
          this.userUID = '';
          this.userEmail = '';
          this.loggedIn = false;
          this.isAdmin = false;
        });
      }
    })
  }

  async fetchCurrentUser() {
    // const userDoc = await firebase.firestore().collection(USERS_COLLECTION).doc(this.userUID).get();
    const adminDoc = await firebase.firestore().collection(ADMINS_COLLECTION).doc(this.userUID).get();
    let userDoc = {};

    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await axios({
        url: `${API_URL_BASE}/me`,
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        method: 'get'
      });

      console.log(userDoc)
      userDoc = response.data
    } catch (e) {
      console.log(e);
    }

    const isAdmin = (adminDoc.exists && adminDoc.data().isAdmin === true);

    runInAction(() => {
      this.userDoc = userDoc;
      this.users[this.userUID] = userDoc;
      this.isAdmin = isAdmin;
      this.fetchedUser = true;
    })
  }

  async fetchQuiz() {
    this.fetchingQuiz = true;
    const doc = await firebase.firestore().collection('quizzes').doc('quiz').get()
    const quiz = QuizSchema.cast(doc.data());

    runInAction(() => {
      this.quiz = quiz;
      this.fetchingQuiz = false;
      this.fetchedQuiz = true;
    });
  }

  async updateUser(uuid, values) {
    // Update or create doc if it doesn't exist
    const docAlreadyExistsCheck = await firebase.firestore().collection(USERS_COLLECTION).doc(uuid).get();
    if (docAlreadyExistsCheck.exists) {
      await firebase.firestore().collection(USERS_COLLECTION).doc(uuid).update(values);
    } else {
      await firebase.firestore().collection(USERS_COLLECTION).doc(uuid).set(values);
    }

    if (uuid === this.userUID) {
      await this.fetchCurrentUser();
    }

    await this.fetchUser(uuid);
  }

  async fetchUser(uuid) {
    const userDoc = await firebase.firestore().collection(USERS_COLLECTION).doc(uuid).get()
    const user = userDoc.data();
    user.eventDay = DATE_VALUES[user.eventDay] || '';
    runInAction(() => {
      this.users[uuid] = UserSchema.cast(user);
    });
  }

  async fetchUsers() {
    if (this.fetchingUsers === true) {
      return;
    }

    runInAction(() => {
      this.fetchingUsers = true;
    })

    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const response = await axios({
      url: `${API_URL_BASE}/users`,

      headers: {
        'Authorization': `Bearer ${idToken}`
      },
      method: 'get'
    })

    const users = response.data;

    console.log(Object.keys(users).length)

    runInAction(() => {
      this.fetchingUsers = false;
      this.users = users;
      this.fetchedUsers = true;
    });
  }

  async answerQuestion(questionUUID, answerUUID) {
    let newUserDoc = {
      answers: {}
    };

    try {
      const userDoc = await firebase.firestore().collection('users').doc(this.userUID).get()
      if (userDoc.exists) {
        newUserDoc = { ...newUserDoc, ...userDoc.data() }
      }

      set(newUserDoc, `answers[${questionUUID}]`, answerUUID)
    } catch (e) {
      console.log(e);
    }
  }

  async deleteUser(uuid) {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      await axios({
        url: `${API_URL_BASE}/api/users/${uuid}`,
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        method: 'delete'
      });
      await this.fetchUsers();
      runInAction(() => {
        delete this.users[uuid]
      })
    } catch (e) {
      console.log(e);
    }
  }

  get numberOfQuestions() {
    return this.quizQuestions.length;
  }

  get filteredUsers() {
    const { search, city, group, eventDay } = this;
    const filteredUsers = {};

    Object.keys(this.users).forEach(userUID => {
      let isValid = true;

        // Search
        var lnv = true;
        var fnv = true;
        var sv = true;

        if (
          typeof search === 'string' &&
          search.trim().length > 0 &&
          (typeof this.users[userUID].firstName === 'string' && this.users[userUID].firstName.toLowerCase().indexOf(search.trim().toLowerCase()) === -1)
        ) {
          lnv = false;
        }

        if (
          typeof search === 'string' &&
          search.trim().length > 0 &&
          (typeof this.users[userUID].lastName === 'string' && this.users[userUID].lastName.toLowerCase().indexOf(search.trim().toLowerCase()) === -1)
        ) {
          fnv = false;
        }
        if (
          typeof search === 'string' &&
          search.trim().length > 0 &&
          (typeof this.users[userUID].email === 'string' && this.users[userUID].email.toLowerCase().indexOf(search.trim().toLowerCase()) === -1)
        ) {
          sv = false;
        }

        if (lnv || fnv || sv) {
          // found
        } else {
          isValid = false;
        }

        // City
        if (
          city !== undefined && city.trim().length > 0 &&
          this.users[userUID].city !== city
        ) {
          isValid = false;
        }

        // Group
        if (group !== undefined && group.trim().length > 0 &&
          this.users[userUID].group !== group
        ) {
          isValid = false;
        }

        // Event Day
        if (
          eventDay !== undefined && eventDay.toString().length > 0 &&
          parseInt(this.users[userUID].eventDay) !== parseInt(eventDay)
        ) {
          isValid = false;
        }

        if (isValid) {
          filteredUsers[userUID] = this.users[userUID];
        }
    })

    return filteredUsers;
  }

  updateFilters(key, value) {
    if (['search','city','group','eventDay'].indexOf(key) === -1) {
      return;
    }

    this[key] = value;
  }

  get adminLoaded() {
    return this.fetchedAnswers && this.fetchedUsers
  }

  monitorOnlineOfflineStatus() {
    const connectedRef = firebase.database().ref('.info/connected');
    connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        runInAction(() => {
          this.onlineTime = new Date().valueOf();
        })
      } else {
        runInAction(() => {
          this.offlineTime = new Date().valueOf();
        })
      }
    });
  }

  get isOffline() {
    if (
      this.onlineTime !== 0 &&
      this.offlineTime !== 0 &&
      this.onlineTime < this.offlineTime
    ) {
      return true;
    }

    return false;
  }

  get userAnswers() {
    return this.userDoc.answers
  }
}

decorate(AppStore, {
  // Quiz
  quiz: observable,
  // numberOfQuestions: computed,
  fetchingQuiz: observable,
  fetchedQuiz: observable,

  // Auth
  checkedAuth: observable,
  fetchedUser: observable,
  userUID: observable,
  userEmail: observable,
  loggedIn: observable,
  userIsAdmin: observable,
  userDoc: observable,
  userAnswers: computed,
  isAdmin: observable,

  // Users
  users: observable,
  fetchingUsers: observable,
  fetchedUsers: observable,

  // Filters
  search: observable,
  city: observable,
  group: observable,
  eventDay: observable,

  // Actions that update state
  signUp: action.bound,
  signOut: action.bound,
  monitorAuthState: action.bound,
  fetchCurrentUser: action.bound,
  fetchUsers: action.bound,
  fetchQuiz: action.bound,
  updateFilters: action.bound,
  updateUser: action.bound,

  // Derived/Computed Data
  filteredUsers: computed,
  adminLoaded: computed,

  // OnlineOffline
  monitorOnlineOfflineStatus: action.bound,
  onlineTime: observable,
  offlineTime: observable,
  isOffline: computed
})

export default AppStore;
