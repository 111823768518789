import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
import { Provider } from 'mobx-react';
import 'firebase/firestore';
import 'firebase/database';
import ReactModal from 'react-modal';
import 'tachyons/css/tachyons.min.css';
import App from './components/App';
import AppStore from './stores/App';
import './index.css';

firebase.initializeApp({
  apiKey: "AIzaSyA-4yUC8K0BSNESg2b4pB3Th19_FvQQoMk",
  authDomain: "subaru-quiz.firebaseapp.com",
  databaseURL: "https://subaru-quiz.firebaseio.com",
  projectId: "subaru-quiz",
  storageBucket: "subaru-quiz.appspot.com",
  messagingSenderId: "1073060708152",
  appId: "1:1073060708152:web:5243d9e22195af47"
});

ReactModal.setAppElement('#modal');

const appStore = new AppStore();

ReactDOM.render(
  <Provider app={appStore}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
