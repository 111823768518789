import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import firebase from 'firebase/app'
import 'firebase/firestore';
// import { DragDropContext } from 'react-beautiful-dnd';
import uuidv4 from 'uuid/v4'
import Loading from './Loading'

class QuizBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
  }
  
  async componentDidMount() {
    await this.setState({loading: true})
    await this.props.fetchQuiz()
     await this.setState({loading: false, loaded: true})
  }

  async onSubmit(values) {
    await firebase.firestore().collection('quizzes').doc('quiz').set(values, { merge: false })
  }

  render() {
    const { loaded, loading } = this.state;
    const { initialValues } = this.props;
    if (!loaded || loading) {
      return <Loading />
    }
    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <form style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }} className="athelas mv3" onSubmit={handleSubmit}>
            <FieldArray name="sections">
              {(sectionsProps) => (
                <div>
                  {sectionsProps.fields.map(sectionsField => (
                    <div style={{ background: '#fff', margin: '2rem', boxShadow: '0 0 0.5rem 0 rgba(0,0,0,.2)', padding: '2rem' }} key={sectionsField}>
                      <label style={{ display: 'block'}}>Section Title</label>
                      <Field
                        className="input-reset ba b--black-20 pa2 mb2 db w-100"
                        component="input"
                        type="text"
                        name={`${sectionsField}.title`}
                        placeholder="Section Title"
                      />
                      <FieldArray name={`${sectionsField}.questions`}>
                        {(questionsProps) => (
                          <div className="list pl0 ml0 center mw-100 ba b--light-silver br3">
                            <div>
                              {questionsProps.fields.map(questionsField => (
                                <div className="pa3 bb b--light-silver" key={questionsField}>
                                  <div>
                                    <label style={{ display: 'block'}}>Question</label>
                                    <Field
                                      placeholder="Question"
                                      component="input"
                                      type="text"
                                      className="input-reset w-100"
                                      name={`${questionsField}.question`}
                                    />
                                  </div>
                                  <div>
                                    <label style={{ display: 'block'}}>Message to show if it is answered correctly</label>
                                    <Field
                                      placeholder="Correct answer message"
                                      component="textarea"
                                      name={`${questionsField}.correctAnswerMessage`}
                                      className="input-reset w-100"
                                    />
                                  </div>
                                  <FieldArray name={`${questionsField}.answers`}>
                                    {(answersProps) => (
                                      <div>
                                        <h3>Answers</h3>
                                        <div className="list pl0 ml0 center mw-100 ba b--light-silver br3 mb2">
                                          {answersProps.fields.map(answersField => (
                                            <div className="pa3 bb b--light-silver" key={answersField}>
                                              <Field
                                                placeholder="Answer"
                                                name={`${answersField}.answer`}
                                                component="input"
                                                type="text"
                                                className="input-reset w-100"
                                              />
                                              <Field name={`${answersField}.isCorrect`}>
                                                {({ input: { value, onChange } }) => (
                                                  <button type="button" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (value === true) {
                                                      onChange(false)
                                                    } else {
                                                      onChange(true)
                                                    }
                                                  }}>{(value === true ? 'This answer is correct' : 'This answer is incorrect')}</button>
                                                )}
                                              </Field>
                                            </div>
                                          ))}
                                        </div>
                                        <button
                                          type="button"
                                          onClick={() => (
                                            answersProps.fields.push({
                                              uuid: uuidv4(),
                                              answer: '',
                                              isCorrect: false
                                            })
                                          )}
                                        >Add Answer</button>
                                      </div>
                                    )}
                                  </FieldArray>
                                </div>
                              ))}
                            </div>
                            <button
                              type="button"
                              onClick={() => (
                                questionsProps.fields.push({
                                  question: 'Question',
                                  correctAnswerMessage: 'mess'
                                })
                              )}
                            >
                              Add Question
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => (
                      sectionsProps.fields.push({
                        questions: [{
                          question: '',
                          uuid: uuidv4(),
                          answers: [{
                            answer: '',
                            isCorrect: false,
                          }]
                        }],
                        uuid: uuidv4()
                      })
                    )}
                  >
                    Add Section
                  </button>
                </div>
              )}
            </FieldArray>

            <button type="submit">
              _S_A_V_E_
            </button>
          </form>
        )}
      />
    );
  }
}

export default inject((stores)=>({
  fetchQuiz: stores.app.fetchQuiz,
  initialValues: stores.app.quiz
}))(observer(QuizBuilder))