import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import SuccessMessage from './SuccessMessage';
import Button from './Button';
import ErrorMessage from './ErrorMessage'
import '../styles/Question.css';

class Question extends Component {
  static validate(values) {
    const errors = {};

    if (values.answer === '') {
      errors.answer = 'Invalid';
    }

    return errors;
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitFailed: false,
      redirectToSurvey: false
    };
  }

  componentDidMount() {
    this.props.fetchQuiz();
  }

  async onSubmit(values) {
    const { userUID, userAnswers, fetchCurrentUser, userDoc } = this.props;
    const { questionID } = this.props.match.params;
    const newUserAnswers = {
      ...{ [questionID]: values.answer },
      ...userAnswers,
    };

    let isLastQuestion = false;
    
    if (userDoc.quizResults.numUnanswered === 1) {
      isLastQuestion = true;
    }

    await firebase.firestore().collection('users').doc(userUID).update({
      answers: newUserAnswers
    })

    await fetchCurrentUser();
    
    if (isLastQuestion) {
      await new Promise((resolve) => {
        this.setState({redirectToSurvey: true}, () => resolve())
      })
    }

    window.scrollTo(0, 0);
  }

  render() {
    const { quiz, userAnswers, isOffline, urlPrefix } = this.props;
    let questionIndex = 0;
    let questionFound = false;
    let question = {};
    let correctAnswerUUID = '';
    let nextQuestionUUID = '';
    let sectionName = '';

    if (this.state.redirectToSurvey) {
      return <Redirect to='/quiz/survey' />
    }

    const { quizSectionID, questionID } = this.props.match.params;

    quiz.sections.forEach(section => {
      if (section.uuid === quizSectionID) {
        section.questions.forEach((sectionQuestion, sectionQuestionIndex) => {
          if (sectionQuestion.uuid === questionID) {
            questionFound = true;
            question = sectionQuestion;
            questionIndex = sectionQuestionIndex

            sectionQuestion.answers.forEach(answer => {
              if (answer.isCorrect) {
                correctAnswerUUID = answer.uuid;
              }
            })

            if (sectionQuestionIndex <= section.questions.length - 2) {
              nextQuestionUUID = section.questions[sectionQuestionIndex + 1].uuid;
            }
          }
        })
      }
    })

    if (!questionFound) {
      return null;
    }

    let userAnswered = false;
    let userAnswer = undefined;

    if (userAnswers !== undefined && userAnswers[questionID] !== undefined) {
      userAnswered = true;
      if (userAnswers !== undefined) {
        userAnswer = userAnswers[questionID];
      }
    }

    // const userAnswered = userAnswers[questionID] !== undefined;
    
    const initialValues = {};

    if (userAnswered) {
      initialValues.answer = userAnswers[questionID]
    }

    return (
      <Form
        initialValues={initialValues}
        validate={Question.validate}
        onSubmit={this.onSubmit}
      >
        {({ submitting, submitSucceeded, submitFailed, handleSubmit, dirty }) => (
          <div className="Question mw-100 mw7-ns w-100 center">
            <div className="Question-header fixed h3 w-100 flex items-center bb b--light-gray bg-white">
              <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
                <div className="pl3">
                  <Link to={urlPrefix}>
                    <i className="material-icons">home</i>
                  </Link>
                </div>
                <div className="Question-header-title tc lh-solid">
                  <h1 className="f5 b lh-solid">
                    {`Question ${questionIndex + 1}`}
                  </h1>
                  <span className="f7 moon-gray">{sectionName}</span>
                </div>
                <div className="Question-index pr3">
                  <Link to={`${urlPrefix}/${quizSectionID}`}><i className="material-icons">format_list_numbered</i></Link>
                </div>
              </div>
            </div>

            <div className="Question-content pb4 bg-white">
              {isOffline && (
                <div className="Question-content-offline pa3">
                  Bad connection. Don't worry, we'll save your answer. If you're connected to WiFi, try using your mobile data. <strong>Attempting to reconnect...</strong>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {(userAnswer !== undefined && userAnswer === correctAnswerUUID) && (
                  <SuccessMessage>
                    <div className="check flex items-start justify-between w-100">
                      <div className="w-70 b">{question.correctAnswerMessage}</div>
                      <Link to={`${urlPrefix}/${quizSectionID}/${nextQuestionUUID}`} className="Button-small b" style={{ backgroundColor: '#50d400', color: '#fff' }}>Next</Link>
                    </div>
                  </SuccessMessage>
                )}
                {(userAnswer !== undefined && userAnswer !== correctAnswerUUID) && (
                  <ErrorMessage>
                    <div className="check flex items-start justify-between w-100">
                      <div className="w-70 b">{question.correctAnswerMessage}</div>
                      <Link to={`${urlPrefix}/${quizSectionID}/${nextQuestionUUID}`} className="Button-small b" style={{ backgroundColor: '#e65932', color: '#fff' }}>Next</Link>
                    </div>
                  </ErrorMessage>
                )}
                <p className="Question-title pa3">{question.question}</p>
                <ul className="Question-answers list ph3">
                  {question.answers.map((answer) => {
                    let className = '';
                    let checkOrCloseOrNothing = null;

                    if (userAnswered && userAnswer === correctAnswerUUID && answer.uuid === correctAnswerUUID) {
                      className = 'correct-color'
                      checkOrCloseOrNothing = <i className="material-icons-inline correct-color pl2">check_circle</i>;
                    }
                    // User chose this answer, and it is incorrect
                    else if (userAnswered && userAnswer === answer.uuid && answer.uuid !== correctAnswerUUID) {
                      className = 'incorrect-color'
                      checkOrCloseOrNothing = <i className="material-icons-inline incorrect-color pl2">cancel</i>;
                    } else if (userAnswered && correctAnswerUUID === answer.uuid && userAnswer !== answer.uuid) {
                      checkOrCloseOrNothing = <i className="material-icons-inline correct-color pl2">check_circle</i>;
                    }

                    return (
                      <span key={answer.uuid} className="w-100">
                        <li className={`Question-answer flex justify-start items-start lh-copy pv2 ${className}`}>
                          <label htmlFor={answer.uuid} className={`b db pt1 ${className}`}>
                            <Field
                              disabled={userAnswered || submitting}
                              className={className}
                              component="input"
                              type="radio"
                              name="answer"
                              id={answer.uuid}
                              value={answer.uuid}
                            />
                            <span>{answer.answer}</span>
                            {checkOrCloseOrNothing}
                          </label>
                        </li>
                      </span>
                    )
                  })}
                </ul>
                {!userAnswered && (
                  <div className="w-100 mw7-ns tc center mt4 ph3">
                    <Button disabled={!dirty || submitting} type="submit">Submit{submitting ? 'ting' : '' }</Button>
                    <Link to={`${urlPrefix}/${quizSectionID}/${nextQuestionUUID}`} className="f7 moon-gray">Skip Question</Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </Form>
    );
  }
}

export default withRouter(inject(stores => ({
  userDoc: stores.app.userDoc,
  user: stores.app.userUID,
  userAnswers: stores.app.userAnswers,
  quiz: stores.app.quiz,
  isOffline: stores.app.isOffline,
  fetchQuiz: stores.app.fetchQuiz,
  fetchCurrentUser: stores.app.fetchCurrentUser,
  userUID: stores.app.userUID,
}))(observer(Question)));
