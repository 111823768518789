import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/QuizSection.css';

const QuizQuestions = ({
  questions,
  quizSection: { title },
  urlPrefix,
}) => (
  <div className="QuizSection mw-100 mw7-ns center">
    {/* TODO Move the header into the App header? */}
    <div className="QuizSection-header fixed h3 w-100 flex items-center bb b--light-gray bg-white bg-white">
      <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
        <div className="pl3">
          <h1 className="f5 lh-solid">{title}</h1>
          <span className="f7 light-silver">Review</span>
        </div>
        <Link to={`${urlPrefix}/`} className="pr2">
          <i className="material-icons">close</i>
        </Link>
      </div>
    </div>
    <ul className="QuizSection-questions list">
      <div>{console.log(questions)}</div>
    </ul>
  </div>
);

export default QuizQuestions;
