import sortBy from 'lodash/sortBy';

export const JUNE_5_2019 = 'June 5, 2019';
export const JUNE_6_2019 = 'June 6, 2019';
export const JUNE_7_2019 = 'June 7, 2019';
export const JUNE_10_2019 = 'June 10, 2019';
export const JUNE_12_2019 = 'June 12, 2019';
export const JUNE_13_2019 = 'June 13, 2019';
export const JUNE_14_2019 = 'June 14, 2019';
export const JUNE_17_2019 = 'June 17, 2019';
export const JUNE_18_2019 = 'June 18, 2019';
export const JUNE_19_2019 = 'June 19, 2019';
export const JUNE_21_2019 = 'June 21, 2019';
export const JUNE_24_2019 = 'June 24, 2019';
export const JUNE_25_2019 = 'June 25, 2019';
export const JUNE_26_2019 = 'June 26, 2019';
export const JUNE_27_2019 = 'June 27, 2019';

export const JULY_2_2019 = 'July 2, 2019';
export const JULY_3_2019 = 'July 3, 2019';
export const JULY_8_2019 = 'July 8, 2019';
export const JULY_9_2019 = 'July 9, 2019';
export const JULY_10_2019 = 'July 10, 2019';
export const JULY_11_2019 = 'July 11, 2019';
export const JULY_12_2019 = 'July 12, 2019';
export const JULY_16_2019 = 'July 16, 2019';
export const JULY_17_2019 = 'July 17, 2019';
export const JULY_18_2019 = 'July 18, 2019';
export const JULY_19_2019 = 'July 19, 2019';
export const JULY_22_2019 = 'July 22, 2019';
export const JULY_23_2019 = 'July 23, 2019';
export const JULY_24_2019 = 'July 24, 2019';
export const JULY_25_2019 = 'July 25, 2019';
export const JULY_26_2019 = 'July 26, 2019';
export const JULY_30_2019 = 'July 30, 2019';

export const DATE_VALUES = {
  [JUNE_5_2019]: 1,
  [JUNE_6_2019]: 2,
  [JUNE_7_2019]: 3,
  [JUNE_10_2019]: 4,
  [JUNE_12_2019]: 5,
  [JUNE_13_2019]: 6,
  [JUNE_14_2019]: 7,
  [JUNE_17_2019]: 8,
  [JUNE_18_2019]: 9,
  [JUNE_19_2019]: 10,
  [JUNE_21_2019]: 11,
  [JUNE_24_2019]: 12,
  [JUNE_25_2019]: 13,
  [JUNE_26_2019]: 14,
  [JUNE_27_2019]: 15,
  [JULY_2_2019]: 16,
  [JULY_3_2019]: 17,
  [JULY_8_2019]: 18,
  [JULY_9_2019]: 19,
  [JULY_10_2019]: 20,
  [JULY_11_2019]: 21,
  [JULY_12_2019]: 22,
  [JULY_16_2019]: 23,
  [JULY_17_2019]: 24,
  [JULY_18_2019]: 25,
  [JULY_19_2019]: 26,
  [JULY_22_2019]: 27,
  [JULY_23_2019]: 28,
  [JULY_24_2019]: 29,
  [JULY_25_2019]: 30,
  [JULY_26_2019]: 31,
  [JULY_30_2019]: 32,
}

export const CITY_DATES = {
  'Houston': [JUNE_13_2019, JUNE_14_2019],
  'Dallas': [JUNE_18_2019, JUNE_19_2019],
  'Tulsa': [JUNE_24_2019],
  'St. Louis': [JUNE_27_2019],
  'Minneapolis': [JULY_2_2019, JULY_3_2019],
  'Chicago': [JULY_9_2019, JULY_10_2019, JULY_11_2019, JULY_12_2019],
  'Toledo': [JULY_16_2019, JULY_17_2019, JULY_18_2019],
  'Pittsburgh': [JULY_22_2019],
  'Cincinnati': [JULY_25_2019, JULY_26_2019],
  'Orlando': [JUNE_13_2019, JUNE_14_2019],
  'Atlanta': [JUNE_18_2019, JUNE_19_2019],
  'Charlotte': [JUNE_25_2019, JUNE_26_2019],
  'Richmond': [JULY_2_2019],
  'Washington D.C.': [JULY_8_2019, JULY_9_2019],
  'Harrisburg': [JULY_12_2019],
  'Philadelphia': [JULY_17_2019, JULY_18_2019, JULY_19_2019],
  'Syracuse': [JULY_22_2019],
  'Boston': [JULY_25_2019, JULY_26_2019],
  'Newark': [JULY_30_2019],
  'Las Vegas': [JUNE_5_2019, JUNE_6_2019, JUNE_7_2019],
  'Phoenix': [JUNE_10_2019],
  'San Diego': [JUNE_12_2019, JUNE_13_2019],
  'Los Angeles': [JUNE_17_2019, JUNE_18_2019],
  'Sacramento': [JUNE_21_2019],
  'San Francisco': [JUNE_25_2019, JUNE_26_2019],
  'Portland': [JULY_2_2019, JULY_3_2019],
  'Seattle': [JULY_9_2019, JULY_10_2019],
  'Salt Lake City': [JULY_16_2019, JULY_17_2019],
  'Denver': [JULY_23_2019, JULY_24_2019, JULY_25_2019],
}

export const cityOptions = sortBy(Object.keys(CITY_DATES), (o) => o).map(city => ({
  label: city,
  value: city
}));

export const daysOptions = Object.keys(CITY_DATES).reduce((dates, d) => {
  CITY_DATES[d].forEach(date => {
    if (dates.indexOf(date) === -1) {
      dates.push(date)
    }
  })

  return dates;
}, []).map(date => ({
  label: date,
  value: DATE_VALUES[date]
}))

export const fulldayOptions = sortBy(Object.keys(DATE_VALUES), (o) => DATE_VALUES[o]).map(date => ({
  label: date,
  value: DATE_VALUES[date]
}));


export const GROUPS = {
  '1': 'Green Lanyard',
  '2': 'Orange Lanyard',
  '3': 'Blue Lanyard',
  '4': 'Gray Lanyard',
};

export const groupOptions = Object.keys(GROUPS).reduce((groupsOptions, groupID) => {
  return [
    ...groupsOptions,
    {
      label: `${GROUPS[groupID]}`,
      value: groupID,
    }
  ]
}, [])
