import React, { Component } from 'react';

class Arrow extends Component {
  render() {
    return (
      <span className="moon-gray chevron right pt1" />
    );
  }
}

export default Arrow;
