import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import find from 'lodash/find'
import Loading from './Loading';
import Arrow from './Arrow';
import '../styles/QuizSection.css';

class QuizSection extends Component {
  componentDidMount() {
    this.props.fetchQuiz();
  }

  render() {
    const { quizSection, fetchedQuiz, userAnswers, urlPrefix } = this.props;

    if (quizSection === undefined && fetchedQuiz) {
      return null;
    }

    if (this.props.fetchingQuiz || !fetchedQuiz) {
      return <Loading />
    }

    return (
      <div className="QuizSection mw-100 mw7-ns center">
        {/* TODO Move the header into the App header? */}
        <div className="QuizSection-header fixed h3 w-100 flex items-center bb b--light-gray bg-white bg-white">
          <div className="flex items-center justify-between mw-100 mw7-ns w-100 center">
            <div className="pl3 review-title">
              <span className="f7 light-silver">Review</span>
              <h1 className="f5 lh-solid">{quizSection.title}</h1>
            </div>
            <Link to="/quiz" className="b pr3">
              <i className="material-icons">home</i>
            </Link>
          </div>
        </div>
        <ul className="QuizSection-questions list">
          {quizSection.questions.map((question, questionIndex) => {
            const userHasAnswered = Object.keys(userAnswers).indexOf(question.uuid) >= 0;
            let userAnsweredCorrectly = false;
            let userAnswer = '';

            if (userHasAnswered) {
              const userAnswerUUID = userAnswers[question.uuid];
              question.answers.forEach(answer => {
                if (answer.isCorrect) {
                  if (userAnswerUUID === answer.uuid) {
                    userAnsweredCorrectly = true;
                  }
                }

                if (answer.uuid === userAnswerUUID) {
                  userAnswer = answer.answer;
                }
              })
            }

            return (
              <Link to={`${urlPrefix}/${this.props.match.params.quizSectionID}/${question.uuid}`} key={question.uuid}>
                <li className="flex items-start justify-between lh-copy pv3 ph3 bg-white mv1">
                  <span className="w-90 mw-90">
                    {question.question}
                    {userHasAnswered && (<div className="flex i b">Your Answer: {userAnswer}</div>)}
                  </span>
                  {userHasAnswered && !userAnsweredCorrectly && (
                    <i className="material-icons incorrect-color pl2">cancel</i>
                  )}
                  {userHasAnswered && userAnsweredCorrectly && (
                    <i className="material-icons correct-color pl2">check_circle</i>
                  )}
                  {!userHasAnswered && <Arrow />}
                </li>
              </Link>
            );
          })}
        </ul>
        <Link to={urlPrefix} className="b mh3 text-center">
          <div className="BackAll-button br-pill mh3 pa3 mt1 b white tc flex justify-center"><i className="material-icons mr2">home</i> Return Home</div>
        </Link>
      </div>
    );
  }
}

export default withRouter(inject((stores, nextProps) => {
  const quizSection = find(stores.app.quiz.sections, { uuid: nextProps.match.params.quizSectionID })

  return {
    quizSection,
    fetchedQuiz: stores.app.fetchedQuiz,
    fetchQuiz: stores.app.fetchQuiz,
    fetchingQuiz: stores.app.fetchingQuiz,
    userAnswers: stores.app.userAnswers,
  }
})(observer(QuizSection)));
