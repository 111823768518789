import React, { Component } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Modal from './Modal';
import outbacklogo from '../images/Outback_logo.svg';
import legacylogo from '../images/Legacy_logo.svg';
import logo from '../images/Subaru_logo_white.svg';
import '../styles/SplashScreen.css';

class SplashScreen extends Component {
  render() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      return <Redirect to="/quiz" />;
    }

    return (
      <div className="SplashScreen">
        <div className="SplashScreen-header mw7-ns flex items-center justify-between h3 center">
            <img alt="Subaru" src={logo} className="pl3" />
            <Link className="SplashScreen-loginLink pr3" to="/signin">Sign In</Link>
        </div>
        <div className="center tc">
          <div className="w-70 mw7-ns pv5 pv6-ns center">
            <img alt="Outback" src={outbacklogo} />
          </div>
          <div className="Challenge-button-container w-100 center">
            <Link to="/signup">
              <div className="Challenge-button br-pill pa3 b white ttu tracked w-70 w-40-ns center">Take the Challenge</div>
            </Link>
          </div>
          <div className="w-60 mw7-ns pv5 pv6-ns center">
            <img alt="Legacy" src={legacylogo} />
          </div>

        </div>
        <Switch>
          <Route path="/signup" component={Modal} />
          <Route path="/signin" component={Modal} />
          <Route path="/forgot-password" component={Modal} />
        </Switch>
      </div>
    );
  }
};

export default inject((stores => ({
  loggedIn: stores.app.loggedIn
})))(observer(SplashScreen));
