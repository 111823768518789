import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import '../styles/InputField.css';

class InputField extends Component {
  render() {
    const {
      name, type, label, disabled,
    } = this.props;
    return (
      <Field name={name}>
        {({
          input, meta: {
            error, touched, visited, invalid, dirty,
          },
        }) => (
          <div className="InputField">
            <input disabled={disabled} type={type} placeholder={label} {...input} />
            { touched && visited && invalid && <div className="InputField-error">{error}</div>}
          </div>
        )}
      </Field>
    );
  }
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  type: 'text',
  disabled: false,
};

export default InputField;
