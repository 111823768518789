import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Select from 'react-select';
import hasIn from 'lodash/hasIn';
import isString from 'lodash/isString';
import '../styles/SelectField.css';

class SelectField extends Component {
  constructor(props) {
    super(props);

    this.parse = this.parse.bind(this);
    this.format = this.format.bind(this);
  }

  parse(value) {
    const { options } = this.props;

    const validOptionValues = options.map(o => o.value);

    if (isString(value)) {
      if (validOptionValues.indexOf(value) === -1) {
        return null;
      }

      return value;
    }

    if (value === null || !hasIn(value, 'value')) {
      return null;
    }

    if (validOptionValues.indexOf(value.value) !== -1) {
      return value.value;
    }

    return null;
  }

  format(value) {
    const { options } = this.props;
    if (
      value === undefined ||
      value === null ||
      (typeof value === 'string' && value.length === 0)
    ) {
      return null;
    }

    // Invalid Value
    const matchOptions = options.filter(o => (o.value === value.value || o.value === value));
    if (matchOptions.length < 1) {
      return null;
    }

    return matchOptions[0];
  }

  render() {
    const { name, label, options } = this.props;

    return (
      <div className="mb3">
        <Field
          name={name}
          format={this.format}
          parse={this.parse}
          render={({
            input: {
              name, onChange, onBlur, onFocus, value,
            }, meta: {
              error, touched, visited, invalid, dirty,
            },
          }) => (
            <Fragment>
              <label htmlFor={name} className="f7 ttu moon-gray tracked pb3">{label}</label>
              <Select
                isClearable
                onBlur={onBlur}
                onFocus={onFocus}
                id={name}
                onChange={onChange}
                options={options}
                classNamePrefix="react-select"
                defaultValue={value}
              />
              { touched && visited && invalid && <div className="SelectField-error">{error}</div>}
            </Fragment>
          )}
        />
      </div>
    );
  }
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectField;
