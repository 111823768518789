import React, { Component } from 'react';
import '../styles/Button.css';

class Button extends Component {
  render() {
    return (
      <button className="Button w-100 center b mb3" {...this.props} />
    );
  }
}

export default Button;
