import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Form } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import InputField from './InputField';
import Button from './Button';
import { FORM_ERROR } from 'final-form';

const initialState = {
  email: ''
};

class ResetPassword extends Component {
  static validate(values) {
    const errors = {};

    if (isEmpty(values.email)) {
      errors.email = 'This field is required.';
    } else if (!isEmail(values.email)) {
      errors.email = 'Enter a valid email address.';
    }

    return errors;
  }

  static onSubmit({ email }) {
    return firebase.auth().sendPasswordResetEmail(email).then(() => {
      // Email sent.
      return;
    }).catch(() => {
      return {
        [FORM_ERROR]: "Looks like that isn't a valid email address."
      }
    });

  }

  render() {
    const { loggedIn } = this.props;
    if (loggedIn) {
      return <Redirect to="/quiz" />;
    }

    return (
      <Form
        onSubmit={ResetPassword.onSubmit}
        initialState={initialState}
        validate={ResetPassword.validate}
      >
        {({
          submitting, handleSubmit, dirtySinceLastSubmit, submitFailed, submitSucceeded,
        }) => (
          <form onSubmit={handleSubmit}>
            {submitFailed && (
              <span className="submitErrorMsg f6 incorrect">
                Hmmm. We can’t find the information you entered. Try again. Trouble? help@autoevents.io
              </span>
            )}
            <InputField disabled={submitting} name="email" label="Enter your email to reset password" />
            <Button disabled={submitting} type="submit">Send me instructions</Button>
            {!dirtySinceLastSubmit && submitSucceeded && <div className="w-100 pt2 center tc"><span className="submitSuccessMsg correct f6">Sent!</span></div>}
          </form>
        )}
      </Form>
    );
  }
}

export default ResetPassword;
