import {
  object,
  string,
  // number
} from 'yup';

const UserSchema = object().shape({
  firstName: string().required().default(''),
  lastName: string().required().default(''),
  email: string().required().default(''),
  group: string().required().default(''),
  city: string().required().default(''),
  eventDay: string().required().default(''),
  // quizResults: object().shape({
  //   numCorrect: number().required().default(0),
  //   numIncorrect: number().required().default(0),
  //   numUnanswered: number().required().default(0)
  // }),
  answers: object().required().default({})
});

export default UserSchema;