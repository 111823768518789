import { array, object, string, boolean } from 'yup';
import uuidv4 from 'uuid/v4';

const QuizSchema = object().shape({
  sections: array().of(object().shape({
    title: string().required().default(''),
    uuid: string().required().default(() => {
      return uuidv4();
    }),
    questions: array().of(object().shape({
      question: string().required(),
      correctAnswerQuestion: string().required(),
      uuid: string().required().default(() => {
        return uuidv4();
      }),
      answers: array().of(object().shape({
        answer: string().required(),
        isCorrect: boolean().required().default(false),
        uuid: string().required().default(() => {
          return uuidv4();
        })
      }))
    }))
  }))
})

export default QuizSchema;