import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { daysOptions, GROUPS } from '../data';


class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteing: false
    }
    this.deleteUser = this.deleteUser.bind(this);
  }

  async deleteUser(e) {
    e.preventDefault();
    const { user: { firstName, lastName } } = this.props;
    if (window.confirm(`Are you sure you want to delete the user "${firstName} ${lastName}"?`)) {
      await this.setState({ deleteing: true })
      await this.props.deleteUser(this.props.userUUID);
      await this.setState({ deleteing: false })
    }
  }

  render() {
    const {
      userUUID,
      user: {
        firstName,
        lastName,
        email,
        group,
        city,
        eventDay,
        quizResults,
      }
    } = this.props;

    const { numCorrect,
    numIncorrect,
    numUnanswered } = quizResults || {
      numCorrect: 0,
      numIncorrect: 0,
      numUnanswered: 0
    }

    let displayName = `${firstName} ${lastName}`;

    if (displayName.trim().length === 0) {
      displayName = <span>&ndash;</span>;
    }

    let eventDayString = <span>&ndash;</span>;
    if (eventDay) {
      const match = daysOptions.filter(day => parseInt(day.value) === parseInt(eventDay))
      if (match.length === 1) {
        eventDayString = match[0].label;
      }
    }

    let groupString = <span>&ndash;</span>;
    if (group) {
      if (typeof GROUPS[group] === 'string') {
        groupString = GROUPS[group]
      }
    }

    return (
      <tr className={`h3 bb b--light-gray ${this.state.deleteing ? 'deleting-item' : ''}`}>
        <td className="w-10 pr2">
          {displayName}
        </td>
        <td className="w-10 mw4 pr2">{(email && email.length ? email : <span>&ndash;</span>)}</td>
        <td className="w-10 mw4 pr2">{(city && city.length ? city : <span>&ndash;</span>)}</td>
        <td className="w-10 mw2 pr2">{groupString}</td>
        <td className="w-10 mw4 pr2">{eventDayString}</td>
        <td className="w-20 pr0">
          <div className="Button-admin correct b tc db ph2 pt1 pb2 mr2 w3 mw3">
            <i className="material-icons-xs">check_circle</i>
            {numCorrect}
          </div>
          <div className="Button-admin incorrect b tc db ph2 pt1 pb2 mr2 w3 mw3">
            <i className="material-icons-xs">cancel</i>
            {numIncorrect}
          </div>
          <div className="Button-admin gray graybg b tc db ph2 pt1 pb2 w3 mw3">
            <i className="material-icons-xs">help</i>
            {numUnanswered}
          </div>
        </td>
        <td className="w-10 mw2 pr2">
          <div className="w-10 mw2 pr2 flex items-end tr">
            <button
              type="button"
              className="Button-admin delete b fr db ph3 pt1 pb2 mr2 w3 mw3"
              onClick={this.deleteUser}
            >
              Delete
            </button>
            <Link className="Button-admin edit-button fr mw3 b db ph3 pt1 pb2 mr2 w3 mw3" to={`/admin/users/${userUUID}`}>Edit</Link>
          </div>
        </td>
      </tr>
    );
  }
}

export default inject((stores) => ({
  deleteUser: stores.app.deleteUser
}))(observer(UserRow));
