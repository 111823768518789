import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import UserRow from './UserRow';
import '../styles/UsersTable.css';

class UsersTable extends Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    const { users } = this.props;
    return (
      <div>
        <p><strong>{(Object.keys(users).length)} Users</strong></p>
        <table className="UsersTable bg-white">
          <thead>
            <tr>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">Name</button>
              </th>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">Email</button>
              </th>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">City</button>
              </th>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">Group</button>
              </th>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">Event Day</button>
              </th>
              <th>
                <button type="button" className="moon-gray b ttu tracked f7">Results</button>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(users).map(uid => (<UserRow key={uid} userUUID={uid} user={users[uid]} />))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default inject((stores) => ({
  fetchUsers: stores.app.fetchUsers,
  users: stores.app.filteredUsers,
}))(observer(UsersTable));
