import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from "react-select";
import find from 'lodash/find';
import { Redirect, Route } from "react-router-dom";
import UsersTable from './UsersTable';
import Loading from './Loading';
import '../styles/Admin.css';
import { cityOptions, fulldayOptions, groupOptions } from '../data';
import Modal from 'react-modal';
import EditUser from './EditUser';
import UserResults from './UserResults';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingUser: false
    };
  }

  componentDidMount() {
    const { fetchQuiz, fetchUsers } = this.props;
    fetchQuiz();
    fetchUsers();
  }

  render() {
    const {
      checkedAuth,
      fetchedUser,
      isAdmin,
      updateFilters,
      search,
      city,
      group,
      eventDay,
      fetchedUsers,
    } = this.props;

    const cityValue = find(cityOptions, { value: city });
    const groupValue = find(groupOptions, { value: group });
    const eventDayValue = find(fulldayOptions, { value: eventDay });

    if (checkedAuth && fetchedUser && !isAdmin) {
      return <Redirect to="/quiz" />
    }

    if (!fetchedUsers) {
      return <Loading />
    }

    return (
      <div className="Admin f6 mw-100 w-100 ph4 pb6 pt3 center bg-white">
        <div className="Admin-filters flex items-center justify-between">
          <div className="Admin-filter w-25 pr3">
            <div>
              <label className="f7 ttu moon-gray tracked pb3">Search</label>
            </div>
            <input
              value={search}
              name="search"
              type="search"
              onChange={(e) => {
                updateFilters('search', e.target.value)
              }}
              placeholder="Search..."
            />
          </div>
          <div className="Admin-filter w-25 ph3">
            <div>
              <label className="f7 ttu moon-gray tracked pb3">City</label>
            </div>
            <Select
              isClearable
              id={'city'}
              value={cityValue}
              onChange={(value) => {
                if (value === null) {
                  updateFilters('city', undefined);
                } else {
                  updateFilters('city', value.value);
                }
              }}
              options={cityOptions}
              classNamePrefix="react-select"
            />
          </div>
          <div className="Admin-filter w-25 ph3">
            <div>
              <label className="f7 ttu moon-gray tracked pb3">Group</label>
            </div>
            <Select
              isClearable
              id={'group'}
              onChange={(value) => {
                if (value === null) {
                  updateFilters('group', undefined);
                } else {
                  updateFilters('group', value.value);
                }
              }}
              value={groupValue}
              options={groupOptions}
              classNamePrefix="react-select"
            />
          </div>
          <div className="Admin-filter w-25 pl3">
            <div>
              <label className="f7 ttu moon-gray tracked pb3">Date</label>
            </div>
            <Select
              isClearable
              id={'eventDay'}
              value={eventDayValue}
              onChange={(value) => {
                if (value === null) {
                  updateFilters('eventDay', undefined);
                } else {
                  updateFilters('eventDay', value.value);
                }
              }}
              options={fulldayOptions}
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <UsersTable />

        <Route exact path={"/admin/users/:userUID"} render={(routeProps) => (
          <Modal isOpen >
            <div>
              <EditUser
                userUID={routeProps.match.params.userUID}
                cancelEditingUser={this.cancelEditingUser}
              />
              <UserResults userUID={routeProps.match.params.userUID} />
            </div>
          </Modal>
        )} />
      </div>
    );
  }
}

export default inject((stores) => ({
  fetchAnswers: stores.app.fetchAnswers,
  fetchUsers: stores.app.fetchUsers,
  fetchQuiz: stores.app.fetchQuiz,
  fetchedQuiz: stores.app.fetchedQuiz,
  adminLoaded: stores.app.adminLoaded,
  updateFilters: stores.app.updateFilters,
  loggedIn: stores.app.updateFilters,
  checkedAuth: stores.app.checkedAuth,
  fetchedUser: stores.app.fetchedUser,
  isAdmin: stores.app.isAdmin,
  search: stores.app.search,
  city: stores.app.city,
  group: stores.app.group,
  eventDay: stores.app.eventDay,
  fetchedUsers: stores.app.fetchedUsers,
}))(observer(Admin))
