import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import Admin from './Admin';
import QuizBuilder from './QuizBuilder'
import Quiz from './Quiz';
import QuizSection from './QuizSection';
import SplashScreen from './SplashScreen';
import Question from './Question';
import Questions from './QuizQuestions';
import Loading from './Loading';
import QuizHeader from './QuizHeader';
import '../styles/App.css';

class App extends Component {
  componentDidMount() {
    const { monitorAuthState, monitorOnlineOfflineStatus } = this.props;
    monitorAuthState();
    monitorOnlineOfflineStatus();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { checkedAuth, loggedIn, userUID, fetchedUser, isAdmin } = this.props;

    return (
      <div className="App">
        <Route path="/quiz" component={QuizHeader} />
        <Route path="/admin" component={QuizHeader} />
        <Route path="/" exact component={SplashScreen} />
        <Route path="/signup" exact component={SplashScreen} />
        <Route path="/signin" exact component={SplashScreen} />
        <Route path="/forgot-password" exact component={SplashScreen} />
        
        {(!checkedAuth || !fetchedUser) && (
          <Switch>
            <Route path="/signin" render={() => null} />
            <Route component={Loading} />
          </Switch>
        )}
        {(checkedAuth && !loggedIn) && <Redirect to="/signin" />}
        {(checkedAuth && loggedIn && fetchedUser) && (
          <div>
            <div className="main">
              {(isAdmin && <Route exact path={"/admin"} component={Admin} />)}
              {(isAdmin && <Route exact path={"/admin/users/:userUID"} component={Admin} />)}
              {(isAdmin && <Route exact path={"/admin/users/:quizSectionID"} component={Admin} />)}
              {(isAdmin && <Route exact path={"/admin/users/:quizSectionID/:questionID"} component={Admin} />)}
              {(isAdmin && <Route exact path="/admin/quiz-builder" component={QuizBuilder} />)}

              <Route path="/quiz" exact render={(renderProps) => {
                return <Quiz {...renderProps} urlPrefix={'/quiz'} userUID={userUID} />
              }} />
              <Route path="/quiz/survey" exact render={(renderProps) => {
                return <Quiz {...renderProps} urlPrefix={'/quiz'} userUID={userUID} />
              }} />
              <Route path="/questions" exact render={(renderProps) => {
                return <Questions {...renderProps} urlPrefix={`/quiz`} userUID={userUID} />
              }} />
              <Route path="/quiz/:quizSectionID" exact render={(renderProps) => {
                return <QuizSection {...renderProps} urlPrefix={'/quiz'} userUID={userUID} />
              }} />
              <Route path="/quiz/:quizSectionID/:questionID" exact render={(renderProps) => {
                return <Question {...renderProps} urlPrefix={'/quiz'} userUID={userUID} />
              }} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  inject((stores) => ({
    monitorAuthState: stores.app.monitorAuthState,
    monitorOnlineOfflineStatus: stores.app.monitorOnlineOfflineStatus,
    loggedIn: stores.app.loggedIn,
    checkedAuth: stores.app.checkedAuth,
    userUID: stores.app.userUID,
    fetchedUser: stores.app.fetchedUser,
    isAdmin: stores.app.isAdmin,
  }))(observer(App))
);
