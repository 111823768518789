import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { cityOptions, groupOptions, CITY_DATES, DATE_VALUES } from '../data';
import InputField from './InputField';
import SelectField from './SelectField';
import Loading from './Loading';

class EditUser extends Component {
  render() {
    const { fetchingUsers, initialValues, userUID, updateUser } = this.props;

    if (fetchingUsers) {
      return <Loading />
    }

    const formKeys = [
      'firstName',
      'lastName',
      'email',
      'city',
      'eventDay',
      'group'
    ];

    const actualInitialValues = {}

    formKeys.forEach(key => {
      if (initialValues !== undefined && Object.keys(initialValues).indexOf(key) === -1) {
        actualInitialValues[key] = '';
      } else if (initialValues !== undefined) {
        actualInitialValues[key] = initialValues[formKeys]
      }
    })

    return (
      <div className="flex flex-column justify-center">
        <Link className="w-100 mt2 flex items-center b" to="/admin"><i className="material-icons mr2">group</i> All Users</Link>
        <h1>Edit User</h1>
        <Form
          initialValues={initialValues}
          onSubmit={async (values) => {
            await updateUser(userUID, values)
            this.props.history.push('/admin')
          }}
        >
          {({ submitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <InputField disabled={submitting} name="firstName" label="First Name" />
              <InputField disabled={submitting} name="lastName" label="Last Name" />
              <InputField disabled={submitting} name="email" label="Email Address" />
              <SelectField options={cityOptions} disabled={submitting} name="city" label="City" />

              <Field name="city" render={(props) => {
                if (
                  (typeof props.input.value !== 'string') ||
                  (Object.keys(CITY_DATES).indexOf(props.input.value) === -1)
                ) {
                  return (
                    <SelectField
                      options={[]}
                      disabled={true}
                      name="eventDay"
                      label="Event Day"
                    />
                  )
                }

                const daysOptions = CITY_DATES[props.input.value].map(date => ({
                  label: date,
                  value: `${DATE_VALUES[date]}`,
                }))

                return (
                  <SelectField
                    options={daysOptions}
                    disabled={submitting}
                    name="eventDay"
                    label="Event Day"
                  />
                );
              }} />

              <SelectField options={groupOptions} disabled={submitting} name="group" label="Group" />
              <button className="Button w4 ph2 left b" type="submit">{(submitting ? 'Saving...' : 'Save')}</button>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

export default withRouter(inject((stores, nextProps) => ({
  initialValues: stores.app.filteredUsers[nextProps.userUID],
  updateUser: stores.app.updateUser,
  fetchingUsers: stores.app.fetchingUsers,
}))(observer(EditUser)));
