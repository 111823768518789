import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class UserResults extends Component {
  render() {
    const { quiz, userAnswers } = this.props;
    return (
      <div>
        {quiz.sections.map(section => {
          return (
            <div key={section.uuid}>
              <h5 className="mb3 mt4">{section.title}</h5>
              {section.questions.map(question => {
                const correctAnswerUUIDs = question.answers.filter(answer => {
                  return answer.isCorrect;
                }).map(question => question.uuid)

                const answered = Object.keys(userAnswers).indexOf(question.uuid) !== -1;
                const correct = correctAnswerUUIDs.indexOf(userAnswers[question.uuid]) !== -1;

                let userAnswer = '';
                if (answered) {
                  const matches = question.answers.filter(answer => {
                    return answer.uuid === userAnswers[question.uuid]
                  })

                  if (matches.length >= 1) {
                    userAnswer = <div>User Answer: {matches[0].answer}</div>
                  }
                }

                return (
                  <div className="flex mb3" key={question.uuid}>
                    {!answered && (<i className="material-icons-inline pl2 pr3 gray">help</i>)}
                    {answered && correct && (<i className="material-icons-inline correct-color pl2 pr3">check_circle</i>)}
                    {answered && !correct && (<i className="material-icons-inline incorrect-color pl2 pr3">cancel</i>)}

                    <div>
                      {question.question}
                      <div className="b i">{userAnswer}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default inject((stores, nextProps) => ({
  userAnswers: stores.app.users[nextProps.userUID].answers || {},
  quiz: stores.app.quiz,
}))(observer(UserResults));
