import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isString from 'lodash/isString';
import InputField from './InputField';
import SelectField from './SelectField';
import { Field } from 'react-final-form';
import Button from './Button';
import { cityOptions, groupOptions, CITY_DATES, DATE_VALUES } from '../data';

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  city: '',
  eventDay: '',
  group: '',
};

class SignUp extends Component {
  static validate(values) {
    const errors = {};

    const requiredFields = [
      'email',
      'password',
      'firstName',
      'lastName',
      'password',
      'confirmPassword',
      'city',
      'eventDay',
      'group',
    ];
    requiredFields.forEach((field) => {
      if (!hasIn(values, field) || isEmpty(values[field])) {
        errors[field] = 'This is required.';
      }
    });

    if (!isEmpty(values.password) && values.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long.'
    }

    // Verify that password and confirmPassword match
    if (
      !isEmpty(values.password)
      && !isEmpty(values.confirmPassword)
      && values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = 'Passwords must match.';
    }

    if (!isEmpty(values, 'email') && isString(values.email) && !isEmail(values.email)) {
      errors.email = 'Email address must be a valid email address.';
    }

    return errors;
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    const { signUp } = this.props;
    try {
      await signUp(values);
    } catch (e) {
      console.log(e)
      alert('error')
    }
  }

  render() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      return <Redirect to="/quiz" />;
    }

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={INITIAL_VALUES}
        validate={SignUp.validate}
      >
        {({
          handleSubmit, submitting, submitSucceeded, submitFailed,
        }) => (
          <div>
            {submitSucceeded && (
              <span className="submitSuccessMsg f6 correct"><strong>Success!</strong><br/>Setting up your account. One moment please...</span>
            )}
            {submitFailed && (
            <span className="submitErrorMsg f6 incorrect">
              <strong>Whoops!</strong> You forgot to fill out or select an item below. Take a look, and try again.<br/>
              <strong>Trouble?</strong> help@autoevents.io
            </span>
            )}
            <form onSubmit={handleSubmit}>
              <InputField disabled={submitting} name="firstName" label="First Name" />
              <InputField disabled={submitting} name="lastName" label="Last Name" />
              <InputField disabled={submitting} name="email" label="Email Address" />
              <InputField disabled={submitting} type="password" name="password" label="Password" />
              <InputField disabled={submitting} type="password" name="confirmPassword" label="Confirm Password" />
              <SelectField options={cityOptions} disabled={submitting} name="city" label="City" />

              <Field name="city" render={(props) => {
                if (
                  (typeof props.input.value !== 'string') ||
                  (Object.keys(CITY_DATES).indexOf(props.input.value) === -1)
                ) {
                  return (
                    <SelectField
                      options={[]}
                      disabled={true}
                      name="eventDay"
                      label="Event Day"
                    />
                  )
                }

                const daysOptions = CITY_DATES[props.input.value].map(date => ({
                  label: date,
                  value: `${DATE_VALUES[date]}`,
                }))

                return (
                  <SelectField
                    options={daysOptions}
                    disabled={submitting}
                    name="eventDay"
                    label="Event Day"
                  />
                );
              }} />


              <SelectField options={groupOptions} disabled={submitting} name="group" label="Group" />

              <div className="pt3">
                <Button disabled={submitting} type="submit">Sign Up</Button>
              </div>
            </form>
          </div>
        )}
      </Form>
    );
  }
}

export default inject((stores) => ({
  signUp: stores.app.signUp
}))(SignUp);
